<template>
    <router-view :license="license"></router-view>
</template>
<script>
export default {
  props: ["license"],
  data() {
    return {
    };
  }
};
</script>
<style></style>